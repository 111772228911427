import { Appplications } from './Applications'
import { Broadcast } from './Broadcast'
import { Celebration } from './Celebration'
import { DataProcessing } from './DataProcessing'
import { Favorites } from './Favorites'
import { ServerDown } from './ServerDown'
import { Team } from './Team'

export const Illustrations = {
  applications: Appplications,
  broadcast: Broadcast,
  celebration: Celebration,
  dataProcessing: DataProcessing,
  favorites: Favorites,
  serverDown: ServerDown,
  team: Team,
}
