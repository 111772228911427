export const Broadcast = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 605.25 761.48"
  >
    <title>broadcast</title>
    <defs>
      <linearGradient
        id="c07350d9-0c50-4d35-b63a-3c71f5ac29f1-5384"
        x1="304.32"
        y1="452.8"
        x2="304.32"
        y2="148.23"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="gray" stop-opacity="0.25" />
        <stop offset="0.54" stop-color="gray" stop-opacity="0.12" />
        <stop offset="1" stop-color="gray" stop-opacity="0.1" />
      </linearGradient>
    </defs>
    <path
      d="M847.51,148.64a84.83,84.83,0,0,1,31.57,57.89,6.66,6.66,0,0,0,6.82,6l10.24-.3a6.68,6.68,0,0,0,6.46-7.31,108.65,108.65,0,0,0-113.2-98.3,6.69,6.69,0,0,0-6.34,7.42l1.14,10.18a6.65,6.65,0,0,0,6.85,5.92A84.79,84.79,0,0,1,847.51,148.64Z"
      transform="translate(-297.37 -69.26)"
      fill="#ff30ba"
    />
    <path
      d="M839.34,158.88a84.78,84.78,0,0,0-55.4-18.56,6.67,6.67,0,0,0-6.42,7.42l.85,7.59a6.66,6.66,0,0,0,6.74,5.92,64.15,64.15,0,0,1,64.79,56.22,6.68,6.68,0,0,0,6.81,5.89l7.63-.22a6.66,6.66,0,0,0,6.45-7.37A84.82,84.82,0,0,0,839.34,158.88Z"
      transform="translate(-297.37 -69.26)"
      fill="#ff30ba"
    />
    <path
      d="M807.48,198.8a47,47,0,0,1,17.15,29.52,6.73,6.73,0,0,0,6.81,5.77l3.69-.11a6.69,6.69,0,0,0,6.41-7.69,64.15,64.15,0,0,0-63.32-54.82,6.64,6.64,0,0,0-6.52,7.42l.41,3.68a6.73,6.73,0,0,0,6.67,5.93A46.94,46.94,0,0,1,807.48,198.8Z"
      transform="translate(-297.37 -69.26)"
      fill="#ff30ba"
    />
    <path
      d="M352.49,148.64a84.83,84.83,0,0,0-31.57,57.89,6.66,6.66,0,0,1-6.82,6l-10.24-.3a6.68,6.68,0,0,1-6.46-7.31,108.65,108.65,0,0,1,113.2-98.3,6.69,6.69,0,0,1,6.34,7.42L415.8,124.2a6.65,6.65,0,0,1-6.85,5.92A84.79,84.79,0,0,0,352.49,148.64Z"
      transform="translate(-297.37 -69.26)"
      fill="#ff30ba"
    />
    <path
      d="M360.66,158.88a84.78,84.78,0,0,1,55.4-18.56,6.67,6.67,0,0,1,6.42,7.42l-.85,7.59a6.66,6.66,0,0,1-6.74,5.92,64.15,64.15,0,0,0-64.79,56.22,6.68,6.68,0,0,1-6.81,5.89l-7.63-.22a6.66,6.66,0,0,1-6.45-7.37A84.82,84.82,0,0,1,360.66,158.88Z"
      transform="translate(-297.37 -69.26)"
      fill="#ff30ba"
    />
    <path
      d="M392.52,198.8a47,47,0,0,0-17.15,29.52,6.73,6.73,0,0,1-6.81,5.77l-3.69-.11a6.69,6.69,0,0,1-6.41-7.69,64.15,64.15,0,0,1,63.32-54.82,6.64,6.64,0,0,1,6.52,7.42l-.41,3.68a6.73,6.73,0,0,1-6.67,5.93A46.94,46.94,0,0,0,392.52,198.8Z"
      transform="translate(-297.37 -69.26)"
      fill="#ff30ba"
    />
    <path
      d="M577.93,822.91V88.25a19,19,0,0,1,19-19h11.26a19,19,0,0,1,19,19V822.91C627.17,833.35,577.93,833.35,577.93,822.91Z"
      transform="translate(-297.37 -69.26)"
      fill="#535461"
    />
    <rect
      x="120.96"
      y="148.23"
      width="366.73"
      height="304.56"
      rx="44.36"
      ry="44.36"
      fill="url(#c07350d9-0c50-4d35-b63a-3c71f5ac29f1-5384)"
    />
    <rect
      x="125.44"
      y="152.71"
      width="358.31"
      height="295.61"
      rx="44.36"
      ry="44.36"
      fill="#fff"
    />
    <path
      d="M618.47,344.61a4.76,4.76,0,0,1,4.75,4.75v40.83a4.76,4.76,0,0,1-4.75,4.75H581.53a4.76,4.76,0,0,1-4.75-4.75V349.36a4.76,4.76,0,0,1,4.75-4.75h36.95m0-2H581.53a6.75,6.75,0,0,0-6.75,6.75v40.83a6.75,6.75,0,0,0,6.75,6.75h36.95a6.75,6.75,0,0,0,6.75-6.75V349.36a6.75,6.75,0,0,0-6.75-6.75Z"
      transform="translate(-297.37 -69.26)"
      fill="#e0e0e0"
    />
    <line
      x1="302.63"
      y1="288.42"
      x2="302.63"
      y2="299.88"
      fill="none"
      stroke="#6c63ff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <path
      d="M604.8,362.7a10.19,10.19,0,1,1-9.42-.09"
      transform="translate(-297.37 -69.26)"
      fill="none"
      stroke="#6c63ff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </svg>
)
